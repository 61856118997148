/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MarketingPlanStatusEnum = 'DRAFT' | 'PROPOSED' | 'ACCEPTED';

export const MarketingPlanStatusEnum = {
    Draft: 'DRAFT' as MarketingPlanStatusEnum,
    Proposed: 'PROPOSED' as MarketingPlanStatusEnum,
    Accepted: 'ACCEPTED' as MarketingPlanStatusEnum
};

