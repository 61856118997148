/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MarketingPlanEventEnum = 'DEALER_SUBMIT_ANNUAL_PLAN_CREATION' | 'MARKET_MANAGER_REQUEST_MODIFY' | 'MARKET_MANAGER_APPROVE';

export const MarketingPlanEventEnum = {
    DealerSubmitAnnualPlanCreation: 'DEALER_SUBMIT_ANNUAL_PLAN_CREATION' as MarketingPlanEventEnum,
    MarketManagerRequestModify: 'MARKET_MANAGER_REQUEST_MODIFY' as MarketingPlanEventEnum,
    MarketManagerApprove: 'MARKET_MANAGER_APPROVE' as MarketingPlanEventEnum
};

