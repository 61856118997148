/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CampaignAttachmentLinkResponse { 
    id?: number;
    link?: string;
    title?: string;
    createdBy?: string;
    creationTime?: string;
    attachmentTypeEnum?: CampaignAttachmentLinkResponse.AttachmentTypeEnumEnum;
    invoiceAmount?: number;
}
export namespace CampaignAttachmentLinkResponse {
    export type AttachmentTypeEnumEnum = 'FILE' | 'LINK';
    export const AttachmentTypeEnumEnum = {
        File: 'FILE' as AttachmentTypeEnumEnum,
        Link: 'LINK' as AttachmentTypeEnumEnum
    };
}


